@import 'react-responsive-carousel/lib/styles/carousel.min.css';

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('./assets/fonts/MonumentGrotesk-Regular.ttf'),
    url('./assets/fonts/MonumentGrotesk-Medium.ttf'),
    url('./assets/fonts/MonumentGrotesk-Bold.ttf');
}

@font-face {
  font-family: 'Px Grotesk';
  src:
    url('./assets/fonts/Px-Grotesk-Regular.ttf'),
    url('./assets/fonts/Px-Grotesk-Bold.ttf');
}

@font-face {
  font-family: 'Monument Grotesk Semi-Mono';
  src: url('./assets/fonts/MonumentGrotesk-Semi-Mono.ttf');
  font-weight: 400;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  scroll-behavior: smooth;
}

body {
  font-family: 'Monument Grotesk Semi-Mono', sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  max-width: 1380px;
  margin: 0 auto;
}
/* CAROUSEL */
.carousel .slide {
  text-align: left;
}

.carousel-expertise .carousel.carousel-slider {
  overflow: visible;
}

.carousel-expertise .carousel .slider-wrapper {
  overflow: visible;

}

.carousel-expertise .slider {
  max-width: 588px;
  width: 100%;
  gap: 67px;
}

@media screen and (max-width: 768px) {
  .carousel-expertise .slider {
    gap: 0px;
  }
}

@media screen and (max-width: 480px) {
  .carousel-expertise .slider {
    max-width: 360px;
    gap: 0px;
  }
}

@media screen and (max-width: 375px) {
  .carousel-expertise .slider {
    max-width: 320px;
  }
}

.card-best-score .slider {
  max-width: 118px;
  gap: 16px;
}

/* ANIMATIONS - CSSTransition */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


.animation {
  opacity: 0;
  visibility: hidden;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.fade-translateY {
  animation-name: translateY;
}

.fade-translateYReverse {
  animation-name: translateYReverse;
}

.fade-translateX {
  animation-name: translateX;
}

.fade-translateXReverse {
  animation-name: translateXReverse;
}

@keyframes translateY {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20rem);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes translateYReverse {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20rem);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes translateX {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(20rem);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes translateXReverse {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-20rem);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}