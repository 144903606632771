.footer {
  color: #ffffff;
  overflow: hidden;
  background: #000000;
  &-header {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 2rem 5rem;
    @media screen and (max-width: 870px) {
      padding: 2rem;
    }
    @media screen and (max-width: 480px) {
      display: none;
    }
    h3 {
      font-weight: 400;
      font-size: 34.0647px;
      line-height: 47px;
      text-transform: uppercase;
    }
  }
  // &-nav {
  //   display: grid;
  //   grid-template-columns: repeat(4, 1fr);
  //   grid-template-areas: "hola hi ciao oi";
  //   gap: 1rem;
  //   @media screen and (max-width: 768px) {
  //     grid-template-columns: repeat(2, 1fr);
  //     grid-template-rows: repeat(2, 1fr);
  //     grid-template-areas:
  //       "hola ciao"
  //       "hi oi";
  //   }
  //   > li {
  //     &:nth-child(1) {
  //       grid-area: hola;
  //     }
  //     &:nth-child(2) {
  //       grid-area: hi;
  //     }
  //     &:nth-child(3) {
  //       grid-area: ciao;
  //     }
  //     &:nth-child(4) {
  //       grid-area: oi;
  //     }
  //     @media screen and (max-width: 480px) {
  //       &:nth-child(1) {
  //         grid-area: hola;
  //       }
  //       &:nth-child(2) {
  //         grid-area: ciao;
  //       }
  //       &:nth-child(3) {
  //         grid-area: hi;
  //       }
  //       &:nth-child(4) {
  //         grid-area: oi;
  //       }
  //     }
  //   }
  // }
  &-nav-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20%;
    padding: 0 5rem;
    margin-bottom: 10rem;
    @media screen and (max-width: 1000px) {
      gap: 5rem;
      // padding: 0 2rem;
    }
    @media screen and (max-width: 870px) {
      gap: 3rem;
      padding: 0 2rem;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
      padding: 1rem;
      // flex-wrap: wrap;
    }
  }
  :where(&-nav, &-nav-2) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    // margin-bottom: 10rem;
    h3 {
      font-family: 'Px Grotesk';
      font-weight: 400;
      font-size: 32px;
      line-height: 90%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
    }
    a {
      color: #FFFFFF;
      text-decoration: none;
      transition: 300ms;
      &:hover {
        color: #FFFFFF90;
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 480px) {
      gap: 2rem;
    }
  }
  &-nav {
    @media screen and (max-width: 700px) {
      gap: 2rem;
      flex-direction: column;
    }
  }
  &-nav-2 {
    margin-bottom: 0;
    padding: 0 2rem;
    > li {
      text-align: center;
    }
    @media screen and (max-width: 870px) {
      line-height: 26px;
      justify-content: flex-start;
      gap: 1rem;
      align-items: flex-start;
    }
    @media screen and (max-width: 600px) {
      font-size: 13px;
      justify-content: space-between;
    }
    @media screen and (max-width: 480px) {
      gap: 0;
      font-size: 10px;
      line-height: 14px;
      padding: 0 10px;
      > li {
        max-width: 135px;
      }
    }
  }
  &-content {
    margin-bottom: 1rem;
    > img {
      padding: 0 3rem;
      width: 100%;
      @media screen and (max-width: 480px) {
        padding: 0 1rem;
      }
    }
  }
}
