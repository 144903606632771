.landing {
  overflow: hidden;
  &- {
    position: relative;
  }
  &-hero {
    position: relative;
    margin: 4rem;
    @media screen and (max-width: 768px) {
      margin: 4rem 0;
    }
    &-into {
      position: relative;
      text-align: center;
      margin-bottom: 20rem;
      @media screen and (max-width: 768px) {
        margin-bottom: 10rem;
        margin-inline: 1rem;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 5rem;
      }
      &.into-small {
        margin-top: 15rem;
        margin-inline: 3rem;
        @media screen and (max-width: 870px) {
          margin: 10rem 1rem 5rem;
        }
        @media screen and (max-width: 480px) {
        }
        .landing-hero-text {
          h2 {
            font-size: 63.9617px;
            @media screen and (max-width: 870px) {
              font-size: 45px;

              &:nth-child(1) {
                align-self: flex-start;
                text-align: left;
              }

              &:nth-child(3) {
                max-width: 600px;
                align-self: center;
                text-align: center;
              }
            }
            @media screen and (max-width: 480px) {
              font-size: 32px;
              &:nth-child(1) {
                text-align: right;
                max-width: 500px;
                align-self: flex-end;
              }
              &:nth-child(3) {
                max-width: 260px;
                align-self: flex-end;
                // max-width: 410px;
                text-align: right;
              }
            }
            &:nth-child(2) {
              max-width: 799.52px;
              align-self: flex-end;
              @media screen and (max-width: 870px) {
                text-align: right;
                max-width: 80%;
                align-self: flex-end;
              }
              @media screen and (max-width: 480px) {
                text-align: left;
                max-width: 90%;
                align-self: flex-start;
              }
            }
          }
        }
        .landing-hero-img {
          width: 545.06px;
          height: 313.79px;
          top: 78%;
          right: 1rem;
          left: initial;
          @media screen and (max-width: 768px) {
            top: 40%;
            left: 0.4rem;
            width: 300px;
            height: 200px;
          }
          @media screen and (max-width: 480px) {
            width: 245.74px;
            height: 141.47px;
          }
        }
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 768px) {
        gap: 11px;
      }

      h2 {
        font-family: "Px Grotesk";
        font-weight: 400;
        font-size: 85px;
        line-height: 90%;

        letter-spacing: -0.02em;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
          font-size: 50px;
        }
        @media screen and (max-width: 480px) {
          font-size: 35px;
        }

        &:nth-child(1) {
          text-align: left;
          align-self: flex-start;
          max-width: 628px;
          @media screen and (max-width: 768px) {
            max-width: 500px;
          }
          @media screen and (max-width: 480px) {
            max-width: 230px;
          }
        }

        &:nth-child(2) {
          max-width: 780px;
          text-align: right;
          align-self: flex-end;
          @media screen and (max-width: 768px) {
            max-width: max-content;
          }
        }

        &:last-child {
          max-width: 1055.1px;
          @media screen and (max-width: 768px) {
            max-width: 500px;
          }
        }
      }
    }

    &-img {
      position: absolute;
      top: 44%;
      left: 10%;
      width: 695px;
      height: 365px;
      object-fit: cover;
      @media screen and (max-width: 768px) {
        width: 340px;
        height: 228px;
      }
      @media screen and (max-width: 480px) {
        top: 24%;
        width: 240px;
        height: 128px;
      }
    }

    &-approach {
      &__content {
        display: flex;
        justify-content: space-between;
        gap: 74px;
        margin-top: 39px;
        @media screen and (max-width: 870px) {
          flex-direction: column;
          gap: 3rem;
          margin-top: 1rem;
        }

        p {
          font-size: 15px;
          line-height: 150%;
          max-width: 370px;
          @media screen and (max-width: 768px) {
            margin-inline: 1rem;
            max-width: 570px;
          }
          @media screen and (max-width: 480px) {
            font-size: 13px;
          }
        }
      }

      &__img {
        max-width: 815px;
        width: 100%;
        height: auto; //490px;
        object-fit: contain;
        @media screen and (max-width: 480px) {
          height: 241px;
        }
      }
    }

    &-stories {
      &__content {
        display: flex;
        flex-direction: column;
        margin: 10rem 5rem 0;
        @media screen and (max-width: 768px) {
          margin: 5rem 1.5rem 0;
        }
        .stories {
          &-item {
            position: relative;
            min-height: 116px;
            @media screen and (max-width: 768px) {
              min-height: auto;
            }
            // LEFT
            &:nth-child(1) {
              figcaption {
                position: absolute;
                top: -5rem;
                left: 5rem;
                max-width: 445px;
                @media screen and (max-width: 768px) {
                  top: -1.5rem;
                  left: 2rem;
                  max-width: 245px;
                }
              }
            }
            // RIGHT
            &:nth-child(2) {
              text-align: right;
              @media screen and (max-width: 768px) {
                margin-top: 3rem;
                img {
                  position: relative;
                  top: -1rem;
                  right: 3rem;
                }
              }
              @media screen and (max-width: 480px) {
                img {
                  position: relative;
                  top: 0;
                  right: 0;
                }
              }
              figcaption {
                position: absolute;
                top: 0.8rem;
                right: -3.5rem;
                max-width: 675px;
                &[data-lang=it] {
                  max-width: 725px;
                }
                @media screen and (max-width: 870px) {
                  &[data-lang=it] {
                    max-width: 305px;
                  }
                }
                @media screen and (max-width: 768px) {
                  top: 2rem;
                  right: 1.3rem;
                  max-width: 245px;
                  text-align: left;
                }
                @media screen and (max-width: 480px) {
                  top: -1.3rem;
                }
              }
            }
            // LEFT
            &:nth-child(3) {
              text-align: center;
              margin-top: 8rem;
              @media screen and (max-width: 768px) {
                margin-top: 3rem;
                text-align: left;
                img {
                  position: relative;
                  left: 8.5rem;
                }
              }
              @media screen and (max-width: 480px) {
                img {
                  position: relative;
                  left: 0;
                }
              }
              figcaption {
                position: absolute;
                top: -1.9rem;
                left: 0.4rem;
                @media screen and (max-width: 768px) {
                  top: -0.8rem;
                  left: 0.8rem;
                  white-space: nowrap;
                }
                @media screen and (max-width: 480px) {
                  left: 0rem;
                  max-width: 400px;
                  white-space: pre-wrap;
                }
              }
            }
            // RIGHT
            &:nth-child(4) {
              text-align: right;
              @media screen and (max-width: 870px) {
                margin-top: 3rem;
              }
              img {
                position: absolute;
                right: 18.5rem;

                @media screen and (max-width: 768px) {
                  position: relative;
                  right: 0;
                }
              }
              figcaption {
                position: absolute;
                right: 1.3rem;
                top: 3.8rem;
                max-width: max-content;
                @media screen and (max-width: 768px) {
                  top: 3rem;
                  right: -2.5rem;
                  text-align: left;
                  max-width: 250px;
                }
                @media screen and (max-width: 480px) {
                  top: 1rem;
                  right: 0.3rem;
                }
              }
            }
            // LEFT
            &:nth-child(5) {
              margin-top: 8rem;
              @media screen and (max-width: 768px) {
                margin-top: 4rem;
              }
              figcaption {
                position: absolute;
                top: 4rem;
                left: -1.3rem;
                max-width: 557px;
                @media screen and (max-width: 768px) {
                  top: 3.4rem;
                  left: 0.5rem;
                  max-width: 250px;
                }
                @media screen and (max-width: 480px) {
                  top: 2.4rem;
                }
              }
            }
            // RIGHT
            &:nth-child(6) {
              text-align: right;
              margin-bottom: 13.8rem;
              @media screen and (max-width: 870px) {
                margin: 8rem 0;
              }
              @media screen and (max-width: 768px) {
                margin: 3rem 0;
              }
              @media screen and (max-width: 480px) {
                margin: 6rem 0;
              }
              img {
                position: absolute;
                right: 10.8rem;
                @media screen and (max-width: 768px) {
                  position: relative;
                  right: 0;
                }
              }
              figcaption {
                text-align: left;
                position: absolute;
                top: 4.5rem;
                right: 1rem;
                max-width: 605px;
                @media screen and (max-width: 768px) {
                  top: 3rem;
                  // left: 4rem;
                  max-width: 240px;
                }
                @media screen and (max-width: 480px) {
                  top: 2rem;
                }
              }
            }
            // LEFT
            &:nth-child(7) {
              @media screen and (max-width: 870px) {
                margin-block: 3rem;
              }
              img {
                position: absolute;
                left: 17rem;
                @media screen and (max-width: 768px) {
                  position: relative;
                  left: 0;
                }
              }
              figcaption {
                position: absolute;
                top: -1rem;
                max-width: 610px;
                @media screen and (max-width: 768px) {
                  top: 3.1rem;
                  left: 0.8rem;
                  max-width: 220px;
                }
                @media screen and (max-width: 480px) {
                  top: -1rem;
                  left: 3rem;
                }
              }
            }
            // RIGHT
            &:nth-child(8) {
              text-align: right;
              @media screen and (max-width: 480px) {
                margin-top: 3rem;
              }
              img {
                position: absolute;
                right: 22.4rem;
                @media screen and (max-width: 768px) {
                  position: relative;
                  right: 70px;
                }
                @media screen and (max-width: 480px) {
                  right: 0;
                }
              }
              figcaption {
                position: absolute;
                top: 4rem;
                right: 0;
                max-width: 550px;
                text-align: left;
                @media screen and (max-width: 768px) {
                  top: 3.3rem;
                  right: -1rem;
                  max-width: 250px;
                }
                @media screen and (max-width: 480px) {
                  top: -1rem;
                  right: -2rem;
                }
              }
            }
          }
          &-figure {
            img {
              width: 150px;
              height: 85px;
              object-fit: cover;
              @media screen and (max-width: 768px) {
                width: 120px;
                height: 70px;
              }
            }
            figcaption a {
              font-family: "Px Grotesk";
              font-weight: 400;
              font-size: 63.9617px;
              line-height: 90%;
              text-decoration: none;

              letter-spacing: -0.02em;
              text-transform: uppercase;
              color: #000000;
              &:hover {
                color: #3e3e3e;
                text-decoration: underline;
              }
              transition: 300ms;
              @media screen and (max-width: 870px) {
                font-size: 46px;
              }
              @media screen and (max-width: 768px) {
                font-size: 26px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }

    &-clients__content {
      overflow: hidden;
    }

    &-clients__item {
      text-align: center;
      font-family: "Px Grotesk";
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;

      letter-spacing: -0.02em;
      text-transform: uppercase;

      color: #000000;

      &.only {
        margin: 3rem 0;
      }

      &.client-1::before,
      &.client-2::before,
      &.client-3::before,
      &.client-4::before,
      &.client-1::after,
      &.client-2::after,
      &.client-3::after {
        content: "";
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100px;
        background-repeat: no-repeat;
        background-size: 200% 100%;
        background-origin: border-box;
        background-clip: border-box;
        background-position: center;

        background-position: left;
        background-repeat: repeat-x;
        background-blend-mode: darken;

        // ANIMATION
        animation-duration: 50s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        @media screen and (min-width: 1400px) {
          height: 100px;
        }
      }

      &:where(.client-3, .client-4)::after {
        background-size: 80% 100%;
      }

      @media screen and (max-width: 480px) {
        &.client-3::before,
        &.client-4::before,
        &.client-3::after {
          background-size: 200% 50%;
          height: 60px;
          padding: 10px;
        }
        &.client-1::before,
        &.client-2::before,
        &.client-1::after,
        &.client-2::after {
          animation-duration: 30s;
          background-size: 300% 100%;
          height: 60px;
        }
      }
      &.client-1 {
        &::before {
          background-image: url("../assets/img/client/client-1.1.webp");
          animation-name: client-reverse;
        }
        &::after {
          background-image: url("../assets/img/client/client-1.2.webp");
          animation-name: client;
        }
      }
      &.client-2 {
        &::before {
          background-image: url("../assets/img/client/client-1.3.webp");
          animation-name: client-reverse;
        }
        &::after {
          background-image: url("../assets/img/client/client-1.4.webp");
          animation-name: client;
        }
      }
      &.client-3 {
        &::before {
          background-image: url("../assets/img/client/client-3.webp");
          animation-name: client-reverse;
        }
        &::after {
          background-image: url("../assets/img/client/client-4.webp");
          animation-name: client;
        }
      }
      &.client-4 {
        &::before {
          background-image: url("../assets/img/client/client-2.3.webp");
          animation-name: client-reverse;
        }
      }
    }
  }
  &-fullyng-img {
    height: 100vh;
    width: 100%;
    // background-color: #fafafa;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    @media screen and (max-width: 870px) {
      height: 50vh;
    }
    @media screen and (max-width: 480px) {
      overflow: hidden;
      height: 400px;
      margin-bottom: 2rem;
    }
    img {
      position: relative;
      top: initial;
      left: initial;
      width: 100%;
      height: 100vh;
      object-fit: contain;
      @media screen and (max-width: 480px) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-stories,
  &-clients {
    padding: 5rem 4rem;
    @media screen and (max-width: 768px) {
      padding: 5rem 1rem;
    }
  }
  &-clients {
    padding: 5rem 0 !important;
    .title {
      padding: 5rem;
      @media screen and (max-width: 480px) {
        padding: 4rem 1rem;
      }
    }
  }

  &-expertise {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 7rem 3rem 9rem 4rem;
    gap: 5rem;

    overflow: hidden;

    color: #ffffff;
    background: #000000;
    @media screen and (max-width: 870px) {
      padding: 3rem;
    }
    @media screen and (max-width: 480px) {
      padding: 2rem 14px;
      gap: 2rem;
    }
    .expertise__title {
      font-family: "Px Grotesk";
      font-weight: 400;
      font-size: 85px;
      line-height: 90%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media screen and (max-width: 870px) {
        font-size: 50px;
      }
      @media screen and (max-width: 480px) {
        font-size: 28px;
      }
    }

    .expertise__nav {
      display: flex;
      gap: 2rem;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
      }
    }

    .expertise__item {
      font-family: "Px Grotesk";
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;

      letter-spacing: -0.02em;
      text-transform: uppercase;

      color: #ffffff;
      cursor: pointer;
      transition: 300ms;
      &:where(:hover, .active) {
        text-decoration-line: underline;
      }
      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    .carousel-card {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 350px;
      @media screen and (max-width: 768px) {
        margin-right: 2rem;
      }
      .carousel-content {
        h4 {
          font-family: "Px Grotesk";
          font-weight: 400;
          font-size: 20px;
          line-height: 120%;

          letter-spacing: -0.02em;
          text-transform: uppercase;
          margin-bottom: 30px;
          @media screen and (max-width: 480px) {
            font-size: 18px;
          }
        }
        > p {
          font-size: 12px;
          line-height: 150%;
          white-space: pre-line;
          @media screen and (max-width: 480px) {
            font-size: 11px;
          }
        }
      }
    }
    .carousel-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 230px;
      // @media screen and (max-width: 480px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 375px) {
      //   height: 340px;
      // }
    }
    .carousel-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      text-decoration: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 148.4%;
      &:hover {
        color: #ffffff90;
        text-decoration: underline;
      }

      text-transform: uppercase;
      transition: 300ms;
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        svg {
          width: 30px;
        }
      }
    }
  }
}

.title {
  font-family: "Px Grotesk";
  font-weight: 400;
  font-size: 85px;
  line-height: 90%;

  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #191919;

  @media screen and (max-width: 870px) {
    font-size: 70px;
    margin-inline: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 38px;
  }
}

.carousel-card {
  max-width: 588px;
}

@media screen and (max-width: 768px) {
}

@keyframes client {
  to {
    background-position: 200% 0;
  }
}
@keyframes client-reverse {
  to {
    background-position: -200% 0;
  }
}
