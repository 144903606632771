.l-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 80px;
  > img {
    width: 162.67px;
    height: 43.77px;
  }
  &-btn-responsive {
    display: flex;
    gap: 30px;
    > button:nth-child(1) {
      display: none;
    }
  }
  &__nav,
  &__nav-2 {
    display: flex;
    gap: 30px;
  }
  &__item {
    > a {
      font-size: 15px;
      line-height: 21px;
      color: #000000;

      text-decoration: none;
      text-transform: uppercase;
      transition: 300ms;
      &:hover{
        text-decoration: underline;
      }
    }
    &.active > a {
      text-decoration: underline;
    }
    &.external {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.l-menu-btn {
  position: relative;
  display: none;
  z-index: 10;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 20px;
  border: none;

  background-color: transparent;
  box-shadow: none !important;
  transition: 300ms;
}
.l-menu-btn.open .l-menu-btn__burger {
  transform: translateX(-50px);
  background-color: transparent;
}
.l-menu-btn.open .l-menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.l-menu-btn.open .l-menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.l-menu-btn__burger {
  width: 22px;
  height: 2px;
  background-color: #222240;
  border-radius: 5px;
  transition: 0.5s;
}

.l-menu-btn__burger::before,
.l-menu-btn__burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 2px;
  background-color: #222240;
  border-radius: 5px;
  transition: 0.5s;
}
.l-menu-btn__burger::before {
  transform: translateY(-7px);
}
.l-menu-btn__burger::after {
  transform: translateY(7px);
}

@media screen and (max-width: 1110px) {
  .l-header {
    padding: 20px 10px;
    padding-right: 20px;
    z-index: 300;
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);

    > img {
      width: 144px;
    }
    &__nav-2 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-left: 1.2rem;

      position: fixed;
      inset: 84px 0 0 0;
      background-color: #ffffff;

      visibility: hidden;
      transform: translateX(-500px);
      transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
      &.active {
        transform: translateX(0);
        visibility: visible;
      }
    }
  }
  .l-menu-btn {
    display: flex;
  }
}

@media screen and (max-width: 620px) {
  .l-header {
    padding: 20px 10px;
    padding-right: 20px;
    z-index: 300;
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);

    &-btn-responsive {
      > button:nth-child(1) {
        display: flex;
      }
    }
    > img {
      width: 144px;
    }
    &__nav, &__nav-2 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-left: 1.2rem;

      position: fixed;
      inset: 84px 0 0 0;
      background-color: #ffffff;

      visibility: hidden;
      transform: translateX(-500px);
      transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
      &.active {
        transform: translateX(0);
        visibility: visible;
      }
    }
  }
  .l-menu-btn {
    display: flex;
  }
}
